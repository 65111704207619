<template>
  <div class="space-user">
    <src-header></src-header>
    <el-menu class="el-menu-demo" mode="horizontal" :default-active="activeIndex" router>
      <el-menu-item index="user-info">账户信息</el-menu-item>
      <el-menu-item index="my-token">我的Token</el-menu-item>
      <el-menu-item index="service-apply">我的申请</el-menu-item>
    </el-menu>
    <div class="space-user-content">
      <div class="content-item">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { getUser } from '@/shared/userinfo';
import SrcHeader from '../Header.vue';

export default defineComponent({
  name: 'UserCenter',
  components: {
    SrcHeader,
  },
  setup() {
    const activeIndex = ref('user-info');
    getUser();
    const route = useRoute();
    const setPath = (nn) => {
      if (nn.includes('user-info')) {
        activeIndex.value = 'user-info';
      }
      if (nn.includes('my-token')) {
        activeIndex.value = 'my-token';
      }
      if (nn.includes('service-apply')) {
        activeIndex.value = 'service-apply';
      }
    };
    setPath(route.path);
    watch(
      () => route.path,
      (nn) => {
        setPath(nn);
      },
    );
    return {
      activeIndex,
    };
  },
});
</script>

<style lang="scss">
.space-user {
  width: 100%;
  height: calc(100vh - 80px);
  .el-menu.el-menu--horizontal {
    display: flex;
    justify-content: center;
  }
  .el-menu--horizontal > .el-menu-item {
    width: 120px;
  }
  .space-user-content {
    width: 100%;
    padding: 15px 15%;
    background-color: rgba(0, 0, 0, 0.02);
    height: 100%;
    .content-item {
      background-color: white;
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}
</style>
