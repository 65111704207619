<template>
  <div class="header-user-info">
    <header class="src-header">
      <span class="src-header__logo">
        <img :src="LogoImg" alt="" width="112" />
        <el-divider direction="vertical"></el-divider>
        <img :src="LogoTxt" alt="" width="92" />
      </span>
      <ul class="src-header__nav">
        <li>
          <router-link to="/service-resource-center">服务资源中心</router-link>
        </li>
        <li>
          <el-dropdown>
            <a>开发中心</a>
            <template #dropdown>
              <el-dropdown-menu class="src-dropdown">
                <el-dropdown-item @click="handleOpenPage('/freedo/#/admin/developmentCenter/HR')"
                  >高渲染开发文档</el-dropdown-item
                >
                <el-dropdown-item @click="handleOpenPage('/freedo/#/admin/developmentCenter/HR')"
                  >WebGL开发文档</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li>
          <router-link to="/service-monitor-center">服务监控中心</router-link>
        </li>
      </ul>
      <div class="src-header__user">
        <el-dropdown>
          <div class="user-nav">
            <i class="el-icon-user user-nav__icon"></i>
            <span>{{ userInfo?.displayName }}</span>
            <i class="el-icon-arrow-down user-nav__icon"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </header>
  </div>
</template>
<script>
import LogoImg from '@/assets/img/logo-dark.png';
import LogoTxt from '@/assets/img/logo-dark-text.png';
import { userInfo } from '@/layout/messageCenter/user-info';
import { logout } from '@/api/auth';
import { ElMessage } from 'element-plus';
export default {
  name: 'SrcHeader',
  setup() {
    const handleLogout = () => {
      logout()
        .then(() => {
          window.location.href = '/login';
        })
        .catch(() => {
          ElMessage.error('登出失败');
        });
    };
    const handleOpenPage = (url) => {
      window.open(url);
    };
    return {
      LogoImg,
      LogoTxt,
      userInfo,
      handleLogout,
      handleOpenPage,
    };
  },
};
</script>
<style lang="scss" scoped>
.header-user-info {
  .src-header {
    display: flex;
    width: 100vw;
    height: 52px;
    background-color: rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px 0 #e7e7e7;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    align-items: center;
    padding: 0 20px;
    &__logo {
      width: 250px;
      font-size: 20px;
      font-weight: bold;
      color: #111;
      display: flex;
      align-items: center;
    }
    &__nav {
      height: 100%;
      margin-left: 40px;
      display: flex;
      align-items: center;
      margin: 0 20px;
      padding: 0;
      list-style: none;
      li {
        margin-left: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        a {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          color: #333;
          font-size: 14px;
          &.is-disabled {
            color: #999;
            pointer-events: none;
          }
        }
      }
    }
    &__user {
      margin-left: auto;
      display: flex;
      align-items: center;
      .user-nav {
        display: flex;
        align-items: center;
        margin-left: 20px;
        &__icon {
          font-size: 16px;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.src-dropdown {
  .el-dropdown-menu__item {
    line-height: 36px;
  }
}
</style>
